<template>
  <div style="min-height: 200px">
        <b-card class="mb-3">
            <b-overlay :show="loading_list" :opacity="0.0">
                <b-row class="app-local-height">
                    <b-col start>
                        <div class="app-card-tittle">{{trans('settings-roles',207)}}</div>
                    </b-col>
                    <b-col cols="auto" end class="d-flex align-items-center">
                        <div style="min-width:24px; max-width:24px; min-height: 26px">  
                            
                            <b-link v-if="!loading_list" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="getData">
                                <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                            </b-link>

                            <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
                        </div>
                    </b-col>
                </b-row>
               <b-row>
                    <b-table class="text-center" :fields="roles_list" :items="table_items">
                        <template v-slot:cell(Administrator)="item">
                            <span v-if="item.value == 1"><b-icon icon="check2" class="mr-1 fa-lg"></b-icon></span>
                        </template>
                        <template v-slot:cell(HQ_Medewerker)="item">
                            <span v-if="item.value == 1"><b-icon icon="check2" class="mr-1 fa-lg"></b-icon></span>
                        </template>
                        <template v-slot:cell(HappyOne_gebruiker)="item">
                            <span v-if="item.value == 1"><b-icon icon="check2" class="mr-1 fa-lg"></b-icon></span>
                        </template>
                    </b-table>
                </b-row>
                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </b-card>
  </div>
</template>

<script>
import axios from "@axios";

export default {

    created(){
        this.getData();
    },

    data(){
        return{
            roles_list: [
                {key:" ", label: " "},
                {key:"Administrator", label: "Administrator"},
                {key:"HQ_Medewerker", label: "HQ medewerker"},
                {key:"HappyOne_gebruiker", label:"HappyOne gebruiker"}],
            permissions_list: null,
            table_items: [],
            loading_list: false
        }
    },

    methods: {
        getData: function(){
            this.loading_list = true;

            axios
                .post("settings/roles/getData")
                .then((res) => {
                    this.permissions_list = res.data;
                    this.loading_list = false;
                })
                .catch((error)=>{
                    console.error(`error during request: ${error}`);
                })
                .finally(()=>{
                    this.splitData();
                });

        },

        splitData: function(){
            this.table_items = [];
            this.permissions_list.forEach(element => {
                var roles = element.roles.split(',');
                this.table_items
                    .push({
                        " ": element.name, 
                        "Administrator": (roles.find(item => item == "Administrator")) ? 1 : 0,
                        "HQ_Medewerker": (roles.find(item => item == "HQ medewerker")) ? 1 : 0,
                        "HappyOne_gebruiker": (roles.find(item => item == "HappyOne gebruiker")) ? 1 : 0
                        });
            });
            this.table_items.pop();

        },

    }


}
</script>

<style scoped>
.app-local-height{
    height:10px !important;
}
</style>